@import "../../index.scss";

.bars {
  display: flex;
  position: fixed;
  top: 0;
  background: $barGreen4;
  min-width: 300px;
  width: 100%;
  height: 50px;
  min-height: 35px;
  z-index: +999;
  opacity: 1;
  // transition: all 0.3s ease;
  .bars__logo {
    padding: 0.15rem 0.2rem;
    display: flex;
    img {
      width: auto;
      height: 45px;
    }
  }
}
.bars.Subvencije {
  background: $silver5;
}
.bars.Produktna.berza {
  background: $gold6;
}
@media screen and (max-width: 1023px) {
  .bars {
    transition: all 0.3s ease-out;
  }
  .bars.small {
    top: -50px;
    transition: all 0.3s ease-out;
  }
}
@media screen and (min-width: $large) {
  .bars {
    min-width: 1024px;
    width: 100%;
    height: 7rem;
    margin: 0;
    background: white;
    box-shadow: 0 -1px 8px $grey2;
    .bars__links {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
    }
  }
  .bars.small {
    height: 5rem;
    .bars__logo {
      // left: calc(50% - 645px);
      img {
        height: 3.2rem;
      }
    }
  }
  .bars.Subvencije {
    background: transparent;
  }
  .bars.Produktna.berza {
    background: transparent;
  }
}
