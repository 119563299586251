@import "../../index.scss";
.news {
  margin-top: 6rem;
  h1 {
    margin: 1rem 0 0.6rem 0.3rem;
    display: inline-block;
    border-bottom: 4px solid $barGreen4;
    line-height: 1.6rem;
    font-size: 1.4rem;
  }
  h2 {
    padding: 0;
    a {
      font-size: 1.6rem;
      color: $barGreen4;
    }
  }
  .home__box__1 {
    align-items: center;
    .home__rightBox {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 0.4rem;
    }
  }
  .home__box__11 {
    padding: 0 0.4rem;
  }
  .home__box__2 {
    padding: 0 0.4rem;
    .home__box__2-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
  .home__box__3 {
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .news {
    .home > div:nth-child(2) {
      margin-top: auto;
    }
  }
}
@media screen and (min-width: $medium) {
  .news {
    // margin-top: 4rem;
    // padding-bottom: 3rem;
    padding-top: 1.5rem;
    position: relative;
    z-index: +100;
    background: white;
    padding: 1.5rem 2rem;
    h1 {
      padding: 0.3rem 0;
      margin: 0.6rem 0;
      font-size: 2.2rem;
      color: $barGreen4;
    }
    h2 {
      a {
        font-size: 2rem;
        color: $barGreen4;
        padding: 0;
      }
    }
    .home__box__1 {
      display: flex;
      flex-direction: row;
      border-radius: 5px;
      height: 30rem;
      background: linear-gradient(to right, $silver2 15%, $silver3 30%, $silver4 50%, $silver5 55%, $silver3 110%);
      .home__box {
        min-width: 550px;
        height: 100%;
      }
      .home__rightBox {
        margin: 0 0.6rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        .boxWrapper:last-child {
          border: none;
        }
      }
    }
    .home__box__11 {
      display: flex;
      margin: 0.5rem 0;
      padding: 0;
      .home__rightBox {
        width: calc(100% - 340px);
      }
      .home__adBox {
        margin-left: 40px;
        width: 300px;
      }
    }
    .home__box__71 {
      margin: 2rem 0;
      display: flex;
      flex-direction: column;
      h2 {
        a {
          width: 100%;
          display: inline-block;
          line-height: 2.4rem;
          font-size: 2rem;
        }
      }
      .home__box__71-wrapper {
        display: flex;
        .home__rightBox {
          margin-right: 2rem;
          display: grid;
          grid-template-rows: 1fr 1fr 1fr;
          .boxWrapper:last-child {
            border: none;
          }
        }
      }
    }
    .home__box__2 {
      padding: 0;
      margin: 2rem 0;
      display: flex;
      flex-direction: column;
      h2 {
        margin-bottom: 0.5rem;
        width: 100%;
        display: inline-block;
        line-height: 2.4rem;
        font-size: 2rem;
      }
      .home__box__2-wrapper {
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
    .home__box__3 {
      display: flex;
      flex-direction: column;
      // background: $grey1;
      background: radial-gradient(white, $grey1);
      margin: 1rem 0;
      h2 {
        padding: 0.3rem 1rem;
        font-weight: normal;
        color: $barGreen4;
        font-size: 2rem;
        font-weight: 600;
      }
      .home__box__3-wrapper {
        display: grid;
        flex-direction: row;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;
        padding: 0 1rem 0.5rem 1rem;
      }
    }
  }
}
