@import "../../index.scss";

.admin {
  // display: flex;
  // flex-direction: column;
  a {
    color: black;
    width: 100%;
    display: inline-block;
    font-weight: bold;
    // text-transform: uppercase;
  }
  input {
    width: 100%;
    height: 100%;
  }
  .admin-homePage {
    border: 1px solid black;
    margin: 3rem 0;
    padding: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    div {
      margin: 1rem;
      padding: 0 1.5rem;
      box-shadow: 0 0 3px grey;

      input {
        text-align: center;
        margin: 0.5rem 0;
        height: 50px;
        border: 2px solid grey;
      }
    }
    // .test {
    //   border: 1px solid red;
    //   background: red;
    // }
  }
  .admin-articlePages {
    border: 1px solid black;
    margin: 3rem 0;
    padding: 0.5rem;
    table {
      border: 1px solid green;
      width: 100%;
      td {
        border-right: 1px solid grey;
        border-bottom: 1px solid grey;
      }
    }
  }
  button {
    margin: 1rem;
    border: 2px solid grey;
    border-radius: 15px;
    text-align: center;
  }
}
