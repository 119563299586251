@import "../../index.scss";

.bodyText {
  position: relative;
  margin-top: 0.2rem;
  p {
    font-size: 1.18rem;
    line-height: 1.7rem;
    position: relative;
    span {
      font-size: 1.05rem;
      font-weight: 900;
    }
    a {
      font-weight: 700;
      font-size: 1.2rem;
      color: $blue1;
      text-decoration: underline;
      font-style: italic;
    }
    ul,
    ol {
      padding: 0.2rem 0 0.2rem 1.6em;
      li {
        padding-bottom: 0.12rem;
        font-size: 1.2rem;
      }
    }
    b {
      font-size: 1.18rem;
      color: $barGreen3;
    }
    i {
      font-size: 1.12rem;
    }
  }
  div {
    position: relative;
    img {
      margin: 0.3rem 0;
      width: 100%;
    }
  }
  blockquote {
    margin: 1.3rem 0.2rem;
    padding: 1rem 1.2rem 1.5rem 1.2rem;
    background: $grey2;
    font-size: 1.22rem;
    h3 {
      padding: 0.5rem 0;
      color: $barGreen5;
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
  blockquote::before {
    position: absolute;
    content: "";
    left: 0.7rem;
    right: 0.7rem;
    top: 0.5rem;
    bottom: 0.5rem;
    border: 1px solid white;
    a {
      cursor: pointer;
    }
  }
}

@media screen and (min-width: $medium) {
  .bodyText {
    p {
      font-size: 1.28rem;
      line-height: 1.85rem;
      span {
        font-size: 1.22rem;
      }
      a {
        font-size: 1.3rem;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      ul,
      ol {
        li {
          font-size: 1.22rem;
        }
      }
      b, i {
        font-size: 1.2rem;
      }
    }
  }
  blockquote {
    background: $grey1;
    h2 {
      color: $barGreen3;
    }
  }
}
