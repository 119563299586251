@import "../../index.scss";
.home__1 {
  padding-top: 6.3rem;
  padding-bottom: 2rem;
  .home__box__1 {
    background: $grey1;
    .home__rightBox {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      padding: 0 0.8rem;
    }
  }
  .home__box__2 {
    padding: 0 0.8rem;
    .home__leftBox {
      gap: 0.8rem;
      display: grid;
    }
  }
  .home__box__3 {
    margin: 0.8rem 0;
    padding: 0 0.8rem;
    background: radial-gradient($dark3, $dark4);
    .home_3_title {
      display: flex;
      color: white;
      padding: 0.6rem 0 0 0;
      span {
        font-weight: 700;
        padding: 0.3rem 0 0 0.2rem;
        font-size: 1.4rem;
      }
    }
  }
  .home__box__3.produktna {
    background: radial-gradient($gold4, $gold5);
    .home_3_title {
      h2 {
        display: inline;
        a {
          color: white;
          font-weight: 700;
          padding: 0.3rem 0 0 0.2rem;
          font-size: 1.4rem;
        }
      }
    }
  }
  .home__box__4 {
    padding: 0 0.8rem;
    span {
      font-weight: 700;
      color: $barGreen3;
      font-size: 1.4rem;
    }
    .home__box__4-layout {
      display: grid;
    }
  }
}
@media screen and (min-width: $medium) {
  .home__1 {
    padding-top: 1.5rem;
    position: relative;
    z-index: +100;
    background: white;
    padding: 1.5rem 2rem;
    .home__box__1 {
      margin: 0 auto;
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      flex-direction: row;
      width: 100%;
      .home__box {
        height: 100%;
        background: white;
      }
      .home__rightBox {
        margin: 0 0.6rem;
        height: 100%;
        .boxWrapper:last-child {
          border: none;
        }
      }
    }
    .home__box__2 {
      position: relative;
      display: flex;
      align-items: start;
      height: 640px;
      margin: 0.8rem auto;
      padding: 0;
      .home__leftBox {
        gap: 0;
        margin: 0;
        float: left;
        display: grid;
        //????????????????????????????
        grid-template-rows: 3 (1fr);
        width: calc(100% - 340px);
      }
      .home__rightBox {
        display: flex;
        justify-content: right;
        align-items: center;
        height: 100%;
        width: 340px;
        .desk300x600 {
          display: block;
        }
      }
    }
    .topNews {
      min-height: 410px;
    }
    .home__box__3 {
      width: 100%;
      background: radial-gradient(circle at 100%, $dark3, $dark3 40%, $dark4 80%, $dark2 80%);
      background: linear-gradient(to left, $dark2, $dark2 40%, $dark3 80%, $dark4 80%);
      display: flex;
      flex-direction: column;
      margin: 1.8rem 0 0.8rem 0;
      padding: 0.3rem 1rem 1rem 1rem;
      .home_3_title {
        span {
          font-size: 2rem;
        }
      }
      .box3 {
        .box3__left {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 1rem;
        }
      }
    }
    .home__box__3.produktna {
      background: linear-gradient(to left, $gold3, $gold4 55%, $gold5 72%, $gold6 72%);
      .home_3_title {
        h2 {
          a {
            font-size: 2rem;
          }
        }
      }
    }
    .home__box__4 {
      padding: 0;
      span {
        font-size: 1.8rem;
      }
      .home__box__4-layout {
        margin-bottom: 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
      }
    }
  }
}

@media screen and (min-width: $large) {
  .home__1 {
    position: relative;
    z-index: +100;
    background: white;
    padding: 1.5rem 2rem;
    .home__box__1 {
      display: flex;
      flex-direction: row;
      height: 26rem;
      .home__box {
        min-width: 600px;
      }
    }
  }
}
@media screen and (min-width: $extraLarge) {
  .home__1 {
    .home__box__1 {
      display: flex;
      flex-direction: row;
      height: 28rem;
      .home__box {
        min-width: 600px;
      }
    }
  }
}
