@import "../../index.scss";

.newsAside {
  width: 100%;
  padding: 0 0.4rem;
  .sideBox-wrapper {
    .newsAside-box-title {
      border-bottom: 3px solid $barGreen2;
      padding: 0.2rem 0;
      h2 {
        background: $barGreen2;
        color: white;
        padding-right: 1rem;
        display: inline;
        border-bottom: 4px solid $barGreen2;
        border: 1px solid $barGreen2;
        padding: 0.3rem 0.8rem;
        font-weight: 400;
        line-height: 1.4rem;
        font-size: 1.2rem;
        border-bottom: none;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        letter-spacing: 0.05rem;
      }
    }
    .newsAside-box {
      .newsAside-box-title {
        width: 100%;
      }
    }
  }
}
.newsAside.inText {
  border-bottom: 3px solid $barGreen2;
  padding: 0;
  padding-bottom: 0.2rem;
  margin: 1.5rem 0;
  min-height: 260px;
  // .sideBox-wrapper {
  //   .newsAside-box-title {
  //     h2 {
  //       color: red;
  //     }
  //   }
  // }
}
@media screen and (min-width: $medium) {
  .newsAside {
    width: 380px;
    padding: 0;
    .sideBox-wrapper {
      .newsAside-box {
        margin: 1rem 0;
      }
    }
    .sideBox-wrapper.bigger {
      min-height: 422px;
    }
  }
  .newsAside.inText {
    width: 100%;
    margin: 1.5rem 0;
    // .sideBox-wrapper {
    //   .newsAside-box-title {
    //     border-bottom: 3px solid $barGreen2;
    //   }
    // }
  }
  .newsAside.foot {
    width: 100%;
    .home__box__13,
    .home__box__11 {
      display: inline;
      margin-top: 2rem;
      .newsAside-box-title {
        border-bottom: 3px solid $barGreen2;
        padding: 0.2rem 0;
        h2 {
          background: $barGreen2;
          color: white;
          padding-right: 1rem;
          display: inline;
          border: 1px solid $barGreen2;
          padding: 0.3rem 0.6rem;
          font-weight: 400;
          line-height: 1.4rem;
          font-size: 1.2rem;
          border-bottom: none;
          border-top-left-radius: 0.3rem;
          border-top-right-radius: 0.3rem;
        }
      }
      .home__rightBox {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.8rem;
        padding-top: 1.2rem;
      }
    }
    .home__box__11 {
      display: inline-block;
      position: relative;
      align-items: start;
      .home__rightBox {
        display: grid;
        grid-template-columns: auto;
        // grid-template-rows: 1fr 1fr 1fr;
        gap: 0rem;
        padding: 0.2rem 0;
      }
    }
  }
}
@media screen and (min-width: $extraLarge) {
  .newsAside {
    width: 460px;
    .sideBox-wrapper {
    }
  }
}
