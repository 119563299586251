@import "../../index.scss";

//main news on home and vesti
.mainNews {
  display: flex;
  height: 400px;
  .artBox-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    .artBox-img {
      height: 100%;
      display: block;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        overflow: hidden;
      }
    }
    .artBox-desc {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0 0.4rem;
      background: linear-gradient(to bottom, rgba(143, 143, 143, 0) 0%, #153a3f 12%, #153a3f 15%);
      h3 {
        padding-top: 0.8rem;
        line-height: 1.7rem;
        font-size: 1.4rem;
        color: white;
        font-weight: normal;
      }
      .artBox-desc-other {
        // padding: 0.1rem 0;
        a {
          font-size: 1.1rem;
          font-weight: 600;
          color: $dark2;
          margin-right: 0.2rem;
        }
        span {
          display: none;
          font-size: 0.85rem;
          text-transform: lowercase;
          color: $grey2;
        }
      }
      .artBox-lead {
        display: none;
      }
      button {
        display: none;
      }
    }
  }
}
//main news on home
.mainNews.category {
  height: 320px;
  .artBox-wrapper {
    .artBox-img {
      height: 100%;
      img {
        height: 100%;
      }
    }
    .artBox-desc {
      width: 100%;
      background: linear-gradient(to bottom, rgba(143, 143, 143, 0) 0%, rgba(24, 24, 24, 0.5) 20%, rgba(24, 24, 24, 0.75) 90%);
    }
  }
}
.boxWrapper {
  .artBox-wrapper {
    height: 100%;
    display: flex;
    .artBox-img {
      min-width: 140px;
      img {
        width: 140px;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
    }
    .artBox-desc {
      padding-left: 0.4rem;
      display: flex;
      flex-direction: column;
      width: calc(100% - 140px);
      h3 {
        color: black;
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 1.2rem;
      }
      .artBox-desc-other {
        margin-top: auto;
        padding: 0.4rem 0 0.2rem 0;
        line-height: 0.9rem;
        a {
          font-size: 1rem;
          font-weight: 600;
          color: $dark2;

          margin-right: 0.2rem;
        }
        span {
          display: none;
          font-size: 0.75rem;
          color: $grey2;
          font-weight: 600;
          text-transform: lowercase;
        }
      }
      .artBox-lead {
        display: none;
      }
      button {
        display: none;
      }
    }
  }
}
.boxWrapper:last-child {
  border: none;
}

.boxWrapper.box1 {
  padding: 0.3rem 0;
  height: 120px;
  border-top: 1px solid $silver1;
}

.boxWrapper.box2 {
  display: flex;
  height: 120px;
  padding-bottom: 0.6rem;
  border-bottom: 1px solid $grey4;
  .artBox-wrapper {
    .artBox-desc {
      span {
        color: rgb(168, 168, 168);
        display: inline;
        display: none;
      }
    }
  }
}
.boxWrapper.box2.Spec {
  padding: 0.6rem 0;
}
.boxWrapper.topNews {
  background: $grey1;
  margin-bottom: 1rem;
  padding-top: 0.6rem;
  .artBox-wrapper {
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 0.6rem;
    align-items: center;
    .artBox-img {
      flex: 1;
      aspect-ratio: 3 / 2;
      overflow: hidden;
      width: 100%;
      height: auto;
      img {
        width: 100%;
        border-radius: 0;
      }
    }
    .artBox-desc {
      width: 100%;
      border: 2px solid white;
      padding: 0.3rem;
      margin: 0.6rem;
      a {
        order: 2;
        h3 {
          display: inline-block;
          font-weight: bold;
          font-size: 1.5rem;
          line-height: 1.8rem;
          text-align: center;
        }
      }
      .artBox-desc-other {
        order: 1;
        text-align: center;
        padding: inherit;
        margin-top: 1.5rem;
        padding-bottom: 1rem;
        a {
          color: black;
          text-transform: uppercase;
        }
      }
      .artBox-lead {
        display: inline;
        order: 3;
        color: $grey4;
        text-align: center;
        padding: 0.5rem 0;
        margin-bottom: 1rem;
        font-style: italic;
      }
      button {
        display: inline;
        font-size: 1.1rem;
        order: 4;
        padding: 0.3rem;
        width: 180px;
        border-radius: 0;
        background: $barGreen4;
        color: white;
        text-align: center;
        margin: 1rem auto;
        margin-top: auto;
        z-index: 0;
        &:hover {
          background: $barGreen3;
        }
      }
    }
  }
}

.boxWrapper.box2:last-child {
  border-bottom: none;
}
.boxWrapper.box3 {
  height: 120px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid white;
  margin: 0.5rem 0;
  .artBox-wrapper {
    height: 100%;
    .artBox-img {
      img {
        border-radius: 0.3rem;
      }
    }
    .artBox-desc {
      h3 {
        color: white;
      }
      .artBox-desc-other {
        color: $barGreen4;
        a {
          color: white;
        }
        span {
          display: none;
        }
      }
    }
  }
  &:last-child {
    border-bottom: none;
  }
}
.boxWrapper.box3.produktna {
  background: transparent;
  .artBox-wrapper {
    .artBox-desc {
      h3 {
        font-size: 1rem;
      }
      .artBox-desc-other {
        a {
          color: white;
        }
      }
    }
  }
  &:last-child {
    border: none;
  }
}
.boxWrapper.box4 {
  display: flex;
  height: 120px;
  border-bottom: 1px solid $grey4;
  .artBox-wrapper {
    padding: 0.6rem 0;
    height: 100%;
    .artBox-img {
      height: 100%;
      img {
        height: 100%;
        border-radius: 0.3rem;
      }
    }
  }
  .artBox-desc {
    a {
      h3 {
        color: black;
        font-size: 1rem;
      }
    }
  }
}
.boxWrapper.box4.inText {
  box-shadow: none;
  border-bottom: 1px solid $grey2;
  border-radius: 0;
  margin: 0;
  .artBox-wrapper {
    width: 100%;
    .artBox-img {
      width: 140px;
      img {
        width: 140px;
      }
    }
    .artBox-desc {
      margin: 0;
      width: calc(100% - 140px);
    }
  }
}
.boxWrapper.box4:last-child {
  border: none;
}
.boxWrapper.sideBox {
  display: flex;
  padding: 0.6rem 0 0.5rem 0;
  box-shadow: none;
  color: black;
  border-bottom: 1px solid $grey2;

  .artBox-wrapper {
    .artBox-desc {
      h3 {
        color: black;
        line-height: 1.25rem;
        font-size: 1rem;
        font-weight: 500;
        // border: 1px solid red;
      }
    }
  }
}
.boxWrapper.sideBox,
.boxWrapper.sideBox.newsRightBox {
  height: 140px;
}
.boxWrapper.sideBox:last-child {
  border: none;
}
.boxWrapper.mainBox.bigBox {
  margin: 0.2rem 0;
  .artBox-wrapper {
    .artBox-desc {
      color: black;
      h3 {
        color: black;
      }
      .artBox-desc-other {
        a {
          font-size: 1.1rem;
        }
      }
    }
  }
}
.boxWrapper.mainBox {
  display: flex;
  box-shadow: 0 0 3px 0 rgba(5, 30, 13, 0.1);
  border-radius: 0.3rem;
  .artBox-wrapper {
    flex-direction: column;
    padding: 0;
    width: 100%;
    .artBox-img {
      img {
        width: 100%;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        height: 220px;
      }
    }
    .artBox-desc {
      height: 110px;
      width: 100%;
      padding: 0.3rem;
      h3 {
        color: black;
        line-height: 1.4rem;
        font-size: 1.2rem;
      }
      .artBox-desc-other {
        font-size: 1.1rem;
        margin: 0;
        margin-top: auto;
        span {
          font-size: 1rem;
          display: inline;
          display: none;
        }
      }
    }
  }
}
.boxWrapper.mainBox.box3.dark {
  height: 340px;
  margin: 0.8rem 0;
  .artBox-wrapper {
    .artBox-desc {
      .artBox-desc-other {
        a {
          font-size: 1rem;
          color: $dark2;
        }
        span {
          font-size: 0.9rem;
        }
      }
    }
  }
}
.boxWrapper.mainBox.box3.white {
  height: 340px;
}

@media screen and (min-width: $medium) {
  .mainNews {
    min-width: 50%;
    height: calc(100% - 1.6rem);
    margin: 0.8rem 0.5rem 0.8rem 0.8rem;
    position: relative;
    border-radius: 0.3rem;
    .artBox-wrapper {
      .artBox-img {
        height: 90%;
        overflow: hidden;
        img {
          border-radius: 0.3rem;
        }
      }
      .artBox-desc {
        border: none;
        width: 100%;
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
        margin: 0;
        padding: 0.5rem 0.4rem 0rem 0.4rem;
        position: absolute;
        h3 {
          color: rgb(255, 255, 255);
          font-size: 1.6rem;
          line-height: 1.9rem;
          font-weight: 400;
          cursor: pointer;
          &:hover {
            color: rgb(231, 231, 231);
          }
        }
        .artBox-desc-other {
          padding: 0.2rem 0 0.1rem 0;
          a {
            font-size: 1.2rem;
            color: $dark1;
          }
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .mainNews.category.newsRightBox {
    margin: 0;
    height: 100%;
    .artBox-wrapper {
      height: 26rem;
      min-height: 100%;
      .artBox-img {
        img {
          height: 100%;
          border-radius: 0;
        }
      }
      .artBox-desc {
        cursor: pointer;
        padding: 2.2rem 0.6rem 0.2rem 0.6rem;
        h3 {
          margin: 0;
          font-size: 1.9rem;
          line-height: 2.1rem;
          font-weight: 500;
        }
      }
      .artBox-desc-other {
        a {
          padding: 0;
          font-size: 1.4rem;
          &:hover {
            text-decoration: none;
          }
        }
        span {
          font-size: 1.1rem;
        }
      }
    }
  }
  .boxWrapper {
    color: white;
    display: flex;
    flex-direction: column;
    .artBox-wrapper {
      padding: 0;
      .artBox-desc {
        h3 {
          font-size: 1.2rem;
          line-height: 1.4rem;
          margin: 0;
        }
        .artBox-desc-other {
          line-height: 1.2rem;
          a {
            line-height: 1.1rem;
            color: $dark2;

            margin-right: 0.2rem;
          }
          span {
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .boxWrapper.topNews {
    max-width: 95vw;
    margin: 0 auto;
    height: 460px;
    padding: 0;
    .artBox-wrapper {
      flex-direction: row;
      padding: 0;
      .artBox-img {
        padding: 0;
        height: 100%;
      }
      .artBox-desc {
        width: 340px;
        height: calc(100% - 1rem);
        .artBox-desc-other {
          margin-top: 2rem;
        }
        button {
          font-size: 1.2rem;
          padding: 0.5rem;
        }
      }
    }
  }
  .boxWrapper.mainBox {
    padding: 0;
    .artBox-wrapper {
      .artBox-desc {
        .artBox-desc-other {
          a {
            font-size: 1.1rem;
          }
        }
      }
    }
  }
  .boxWrapper.mainBox.box3.dark {
    height: 340px;
    background: $grey1;
    margin: 0;
    .artBox-wrapper {
      .artBox-img {
        height: 220px;
      }
      .artBox-desc {
        height: 120px;
        a {
          h3 {
            color: black;
          }
        }
        .artBox-desc-other {
          span {
            color: $grey3;
          }
        }
      }
    }
  }
  .boxWrapper.mainBox.box3.white {
    background: white;
    height: 340px;
    color: black;
    .artBox-wrapper {
      .artBox-img {
        height: 220px;
      }
    }
    .artBox-desc {
      h3 {
        padding: 0;
        color: black;
      }
      .artBox-desc-other {
        span {
          color: $grey3;
        }
      }
    }
  }
  .boxWrapper.mainBox.bigBox {
    height: 400px;
    margin: 0;
    .artBox-wrapper {
      height: 100%;
      .artBox-img {
        img {
          height: 280px;
          width: 100%;
        }
      }
      .artBox-desc {
        height: 120px;
        h3 {
          color: black;
          font-size: 1.35rem;
          line-height: 1.6rem;
        }
      }
    }
  }
  .boxWrapper.mainBox.new {
    height: 300px;
    .artBox-wrapper {
      .artBox-img {
        height: 180px;
        img {
          height: 100%;
        }
      }
    }
    .artBox-desc {
      height: 120px;
      h3 {
        line-height: 1.3rem;
      }
    }
  }
  .boxWrapper.sideBox {
    padding: 0.5rem 0;
    height: 120px;
    box-shadow: none;
    .artBox-wrapper {
      .artBox-img {
        img {
          border-radius: 0.3rem;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
        }
      }
      .artBox-desc {
        h3 {
          color: black;
          font-size: 1rem;
          line-height: 1.2rem;
          font-weight: 500;
        }
        .artBox-desc-other {
          span {
            font-size: 0.85rem;
            color: $grey3;
          }
        }
      }
    }
  }
  .boxWrapper.sideBox.newsRightBox {
    height: 140px;
    border-bottom: 1px solid white;
    .artBox-wrapper {
      .artBox-desc {
        h3 {
          color: black;
          font-size: 1.1rem;
        }
      }
    }
  }
  .boxWrapper.box1 {
    height: 158px;
    padding: 0.8rem 0;
    border: none;
    border-bottom: 1px solid $grey2;
    .artBox-wrapper {
      .artBox-img {
        img {
          width: 150px;
        }
      }
      .artBox-desc {
        padding: 0 0rem 0 0.6rem;
        h3 {
          color: white;
          font-size: 1.2rem;
        }
        .artBox-desc-other {
          padding: 0;
          a {
            font-size: 1.1rem;
          }
        }
      }
    }
  }
  .boxWrapper.box1:last-child {
    border: none;
  }
  .boxWrapper.box2 {
    padding: 0.8rem 0;
    height: 210px;
    width: 100%;
    .artBox-wrapper {
      width: 100%;
      height: 100%;
      .artBox-img {
        img {
          padding: 0;
          width: 240px;
          height: 100%; //????
          border-radius: 0.3rem;
          object-fit: cover;
          overflow: hidden;
        }
      }
      .artBox-desc {
        width: calc(100% - 240px);
        h3 {
          font-size: 1.35rem;
          line-height: 1.6rem;
        }
        .artBox-desc-other {
          margin-top: auto;
          color: $dark2;
          letter-spacing: 0.05rem;
          font-weight: bold;
          a {
            font-size: 1.2rem;
          }
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .boxWrapper.box3 {
    background: radial-gradient($dark4, $dark3);
    height: 300px;
    padding: 0;
    border: none;
    border-radius: 0.3rem;
    .artBox-wrapper {
      flex-direction: column;
      .artBox-img {
        height: 180px;
        img {
          border-radius: 0;
          border-top-left-radius: 0.3rem;
          border-top-right-radius: 0.3rem;
          width: 100%;
        }
      }
      .artBox-desc {
        padding: 0.3rem 0.4rem;
        width: 100%;
        height: 120px;
        h3 {
          line-height: 1.35rem;
          font-size: 1.15rem;
        }
        .artBox-desc-other {
          a {
            color: white;
            font-size: 1.1rem;
          }
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .boxWrapper.box3.produktna {
    background: $gold5;
    .artBox-wrapper {
      .artBox-desc {
        h3 {
          font-size: 1.1rem;
        }
      }
    }
  }
  .boxWrapper.box4 {
    box-shadow: 0 0 3px 0 rgba(5, 30, 13, 0.1);
    border: none;
    border-radius: 0.3rem;
    height: 140px;
    padding: 0;
    .artBox-wrapper {
      .artBox-img {
        aspect-ratio: 4 / 3;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 0.3rem;
          border-bottom-left-radius: 0.3rem;
        }
      }
      .artBox-desc {
        padding: 0.2rem 0.4rem;
        h3 {
          color: black;
          font-size: 1.15rem;
          line-height: 1.35rem;
        }
      }
    }
  }
  .boxWrapper.box4.inText {
    .artBox-wrapper {
      .artBox-img {
        img {
          margin: 0;
        }
      }
      .artBox-desc {
        padding: 0 0.3rem;
        a {
          h3 {
            font-size: 2.25;
          }
        }
      }
    }
  }
}
@media screen and (min-width: $extraLarge) {
  .boxWrapper.box2 {
    .artBox-wrapper {
      .artBox-desc {
        h3 {
          font-size: 1.4rem;
          line-height: 1.8rem;
        }
      }
    }
  }
  .boxWrapper.box3 {
    .artBox-wrapper {
      .artBox-desc {
        h3 {
          font-size: 1.25rem;
          line-height: 1.4rem;
        }
      }
    }
  }
  .boxWrapper.box4 {
    .artBox-wrapper {
      padding: 0;
      .artBox-desc {
        h3 {
          font-size: 1.2rem;
          line-height: 1.4rem;
        }
      }
    }
  }
  .boxWrapper.box4.inText {
    .artBox-wrapper {
      padding: 0.6rem 0;
      .artBox-img {
        img {
          margin: 0;
        }
      }
      .artBox-desc {
        padding: 0 0.3rem;
        h3 {
          font-size: 1.25;
        }
      }
    }
  }
  .boxWrapper.sideBox {
    height: 140px;
    .artBox-wrapper {
      .artBox-img {
        width: 170px;
        img {
          width: 160px;
        }
      }
      .artBox-desc {
        h3 {
          line-height: 1.35rem;
          font-size: 1.18rem;
        }
      }
    }
  }
}
