$small: 767px;
$medium: 768px;
$large: 1024px;
$extraLarge: 1256px;

html {
  scroll-behavior: smooth;
}
body {
  font-family: "Times New Roman", Times, serif;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  font-size: 16px;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  transition: 0.3s all east-out;
  text-decoration: none;
  font-display: swap;
}
.banner-wrapper {
  display: flex;
  margin: 1rem auto;
  justify-content: center;
  align-items: center;
  // border: 1px solid red;
  // height: 250px;
}
.banner-wrapper.bilbord {
  margin-top: 2rem;
  // height: 250px;
  // border: 1px solid red;
}
$barGreen1: #4bb473;
$barGreen2: #357e51;
$barGreen3: #245637;
$barGreen4: #1e482e;
$barGreen5: #173623;
$barGreen6: #0f2417;
$barGreen7: #08120c;

$silver0: #6b8f95;
$silver1: #2c7983;
$silver2: #235d64;
$silver3: #1c4d53;
$silver4: #1d4d55;
$silver5: #153a3f;
$silver6: #3c5758;

$blue1: rgb(46, 86, 172);

$grey1: #ececec;
$grey2: #e6e6e6;
$grey3: #7c7c7c;
$grey4: #4f4f4f;
$grey5: #3a3a3a;

$gold0: #fff8e6;
$gold1: #ffdf80;
$gold2: #e6ac00;
$gold3: #a08438;
$gold4: #806000;
$gold5: #664d00;
$gold6: #4d3900;

$dark0: #fb9b79;
$dark1: #e63819;
$dark2: #b82d14;
$dark3: #8a220f;
$dark4: #731c0d;
$dark5: #451108;
$dark6: #2d0c06;

@media only screen and (max-width: $medium) {
  * {
    /*CSS animations*/
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}
