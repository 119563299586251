@import "../../../index.scss";

.admin_article {
  min-height: 500px;
  margin: 4rem 0;
  display: grid;
  grid-template-columns: repeat(1fr, 8);
  position: relative;
  button {
    border: 1px solid black;
    margin: 0 0.5rem;
  }
  .admin-article-content {
    grid-column: 1 / span 6;
    margin: 1rem 0;
    padding: 0 0.5rem;
    form {
      border: 2px solid red;
    }
    .bodyText {
      background: rgb(230, 230, 230);
      margin: 1rem 0;
      padding: 0 0.5rem;
    }
    input {
      width: 100%;
      //   border: 1px solid red;
      //   background: grey;
    }
    textarea {
      width: 100%;
      height: 120px;
      padding: 0 0.2rem;
    }
  }
  .admin-article-buttons {
    // margin-top: 3rem;
    border: 1px solid grey;
    padding: 0.2rem;
    height: 300px;
    grid-column: 7 / span 2;
    display: flex;
    display: grid;
    grid-template-columns: 1fr 1fr;
    // grid-c: 1rem;
    flex-direction: column;
    position: sticky;
    top: 0;
    button {
      height: 30px;
      text-align: center;
      margin-top: 0.5rem;
      border: 1px solid $barGreen4;
    }
  }
}
