@import "../../index.scss";

.singlePost {
  display: flex;
  flex-direction: column;
  padding-top: 7rem;
  padding-bottom: 2rem;
  .content {
    .content-wrapper {
      padding: 1.5rem 0.8rem 0.4rem 0.8rem;
      .mainContent {
        display: flex;
        flex-direction: column;
        .mainContent-wrapper {
          display: flex;
          flex-direction: column;
          .mainContent-cat {
            display: block;
            background: linear-gradient($grey3, $grey5);
            font-size: 1.2rem;
            color: white;
            padding: 0.35rem 1rem;
            border-radius: 0.3rem;
            width: fit-content;
          }
          .mainContent-title {
            color: $barGreen5;
            margin: 2rem 0 1rem 0;
            font-size: 1.8rem;
            line-height: 2.1rem;
          }
          .mainContent-lead {
            line-height: 1.4rem;
            margin-bottom: 0.8rem;
            font-size: 1.2rem;
            font-weight: 700;
          }
          .mainContent-img {
            position: relative;
            width: 100%;
            aspect-ratio: 3/2;
            img {
              display: block;
              aspect-ratio: 3/2;
              width: 100%;
              height: auto;
              object-fit: cover;
              border-radius: 0.3rem;
            }
            .mainContent-img_source {
              position: absolute;
              bottom: 0.5rem;
              left: 0.5rem;
              background: rgba(30, 30, 30, 0.8);
              padding: 0.1rem 0.5rem;
              border-radius: 0.2rem;
              span {
                font-size: 0.9rem;
                color: rgba(255, 255, 255, 0.85);
                a {
                  color: rgba(255, 255, 255, 0.85);
                  font-weight: normal;
                }
              }
            }
          }
          .mainContent-social {
            width: 100%;
            min-height: 60px;
            height: 60px;
          }
          .mainContent-bodyText {
            width: 100%;
            min-height: 150px;
          }
          .mainContent-subtitle,
          .mainContent-subtitle3 {
            padding: 0.8rem 0 0.2rem 0;
            color: black;
            line-height: 1.6rem;
          }
          .mainContent-subtitle {
            margin-top: 0.5rem;
            color: $barGreen5;
            font-size: 1.4rem;
          }
          .mainContent-subtitle3 {
            color: $barGreen3;
            font-size: 1.2rem;
            font-weight: 600;
          }
          .tags {
            margin: 1.5rem 0 1rem 0;
            .tags-title {
              font-size: 1.6rem;
              font-weight: 700;
              border: none;
            }
            .tags__arr {
              margin-top: 0.6rem;
              display: flex;
              flex-wrap: wrap;
              a {
                line-height: 1.1rem;
                font-size: 0.8rem;
                text-align: center;
                text-transform: uppercase;
                padding: 0.3rem 0.6rem;
                margin-right: 0.5rem;
                border-radius: 0.3rem;
                color: white;
                background: $gold4;
                margin-bottom: 0.4rem;
              }
            }
          }
          .time {
            font-size: 1rem;
            color: $grey5;
            font-family: "Times New Roman", Times, serif;
            padding-bottom: 0.2rem;
          }
        }
      }
    }
    .home__box__11 {
      display: none;
    }
    .home__box__13 {
      display: none;
    }
  }
}
@media screen and (min-width: $medium) {
  .singlePost {
    position: relative;
    z-index: 1;
    padding: 0;
    .content {
      z-index: 2;
      margin: 0;
      padding: 1.5rem 2rem 0 2rem;
      background: white;
      .content-wrapper {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;

        .mainContent {
          width: calc(100% - 380px);
          .mainContent-wrapper {
            .mainContent-cat {
              font-size: 1.4rem;
            }
            .mainContent-lead {
              font-size: 1.4rem;
              line-height: 1.6rem;
            }
            .mainContent-subtitle {
              font-size: 1.6rem;
              line-height: 2rem;
            }
            .mainContent-subtitle3 {
              font-size: 1.4rem;
              line-height: 1.8rem;
            }
            .time {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: $extraLarge) {
  .singlePost {
    .content {
      .content-wrapper {
        gap: 2rem;
        .mainContent {
          width: calc(100% - 460px);
          .mainContent-wrapper {
            .mainContent-title {
              font-size: 2.4rem;
              line-height: 2.6rem;
            }
            .mainContent-lead {
              font-size: 1.4rem;
              line-height: 1.6rem;
            }
          }
        }
      }
    }
  }
}
