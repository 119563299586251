@import "../../index.scss";

button {
  z-index: +999;
  cursor: pointer;
  background: transparent;
  border: none;
  border-radius: 5px !important;
  outline: none;
  padding: 0.1em 0.8em;
  text-align: left;

  &:hover {
    animation: buttonAnimation 0.3s forwards ease-in-out;
  }
}
.toTop {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  i {
    color: $barGreen3;
    font-size: 2.8rem;
  }
}
.toTop.close {
  visibility: hidden;
}
.artBox-btn {
  display: none;
}
@media screen and (min-width:1100px) {
  .toTop {
    bottom: 2rem;
    right: 2rem;
  }
}
@keyframes buttonAnimation {
  from {
    color: white;
  }
  to {
    color: rgb(72, 212, 59) !important;
  }
}
