@import "./index.scss";

.app {
  position: relative;
  .app-wrapper {
    display: flex;
    .app-body {
      position: relative;
      margin: 0 auto;
    }
  }
}
.app {
  .app-wrapper {
    .app-body {
      @media screen and (min-width: $medium) {
        margin-top: 6rem;
      }
      @media screen and (min-width: $large) {
        width: 1024px;
      }
      @media screen and (min-width: $extraLarge) {
        width: 1256px;
      }
    }
  }
}
