@import "../../index.scss";

.sticky-banner {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  .sticky-banner-box {
    position: relative;
    height: 100%;
    .sticky-banner-wrapper {
      margin: 0 auto;
      height: 100%;
      text-align: center;
      img {
        width: auto;
        height: 100%;
      }
    }
    .closeButton {
      position: absolute;
      cursor: pointer;
      top: -40px;
      right: 10px;
    }
  }
}
@media screen and (min-width:$medium) {
  .sticky-banner {
    width: 750px;
    left: 50%;
    transform: translateX(-50%);
    .sticky-banner-box {
      .closeButton {
        position: absolute;
        cursor: pointer;
        top: -37px;
        right: 0;
      }
    }
  }
}
